import { Injectable } from '@angular/core';
import { FinanceService } from './finance.service';
import { ERC20ABI } from './constants';
import { Address, Chain, formatUnits, http, parseEther } from 'viem';
import {
  Config,
  createConfig,
  disconnect,
  getAccount,
  readContract,
  reconnect,
  waitForTransaction,
  watchAccount,
  watchAsset,
  watchBlockNumber,
  watchBlocks,
  watchChainId,
  watchClient,
  watchConnections,
  watchConnectors,
  watchContractEvent,
  watchPublicClient,
  writeContract,
} from '@wagmi/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { setAddress, setBalance } from '../core/store/actions/player.actions';
import { SettingsService } from './settings.service';
import { NotificationsService } from '../core/notifications/notifications.service';
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi';
import { createAppKit } from '@reown/appkit';

const USER_EVENTS = [
  'CONNECT_ERROR',
  'DISCONNECT_SUCCESS',
  'DISCONNECT_ERROR',
  'ERROR_FETCH_TRANSACTIONS',
  'SWITCH_NETWORK',
  'SEND_INITIATED',
  'SEND_SUCCESS',
  'SEND_ERROR',
];

export type WalletStatus =
  | 'disconnected'
  | 'connecting'
  | 'connected'
  | 'reconnecting';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  modal!: any;
  modalInitiated = false;

  private walletStatus$$: BehaviorSubject<WalletStatus> =
    new BehaviorSubject<WalletStatus>('disconnected');
  walletStatus$ = this.walletStatus$$.asObservable();

  depositWalletAddress!: string;
  walletConnectProjectId!: string;
  smartContractAddress!: string;
  currencyId!: string;
  network!: Chain;

  private isWalletConnecting$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private isWalletConnecting$ = this.isWalletConnecting$$.asObservable();

  config!: Config;
  constructor(
    private readonly financeService: FinanceService,
    private readonly store: Store,
    private readonly settingsService: SettingsService,
    private readonly notificationsService: NotificationsService,
  ) {
    this.initWallet();
    this.getWeb3Settings();
  }

  initWallet() {
    this.getWeb3Settings().subscribe({
      next: (data) => {
        // console.log('initWallet');
        this.smartContractAddress = data?.fiatCurrencies[0].address;
        this.depositWalletAddress = data?.paymentData?.walletAddress ?? '';
        this.walletConnectProjectId = data.walletConnectProjectId;
        this.currencyId = data?.fiatCurrencies[0].id ?? '';
        this.network = data.web3Settings;

        const wagmiAdapter = new WagmiAdapter({
          projectId: this.walletConnectProjectId,
          networks: [this.network],
        });

        const metadata = {
          name: 'Meta GG',
          description: 'Meta GG crypto portal',
          url: 'https://meta-game-dev-panel.ggsinternal.space',
          icons: ['https://avatars.githubusercontent.com/u/37784886'],
        };

        this.config = createConfig({
          chains: [this.network],
          transports: {
            [this.network.id]: http(this.network.rpcUrls.default.http[0]),
          },
        });

        this.modal = createAppKit({
          allWallets: 'HIDE',
          adapters: [wagmiAdapter],
          networks: [this.network],
          metadata,
          projectId: this.walletConnectProjectId,
          features: {
            analytics: true,
            email: false,
            socials: false,
            swaps: false,
            onramp: false,
          },
          featuredWalletIds: [
            'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
            '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
            'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
            'e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4',
            'c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a',
          ],
          includeWalletIds: [
            'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
            '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
            'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
            'e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4',
            'c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a',
          ],
          themeMode: 'dark',
          enableInjected: true,
          enableWalletConnect: true,
          allowUnsupportedChain: false,
        });

        if (localStorage.getItem('walletState') === 'connected') {
          reconnect(this.config).then((data) => {
            console.log('reconnect');
            console.log(data);
          });
        }

        console.log(localStorage.getItem('walletState'));

        // @ts-ignore
        function handler({ name, icon }) {
          console.log(name, icon);
        }

        this.modal.subscribeWalletInfo(handler);

        this.modal.subscribeState((newState: any) => console.log(newState));

        this.modal.subscribeEvents((event: any) => console.log(event)); // subscribe to events

        watchAccount(this.config, {
          onChange: (data) => {
            console.log('watchAccount');
            console.log(data);
            if (
              data.status === 'connecting' ||
              data.status === 'reconnecting'
            ) {
              this.isWalletConnecting$$.next(true);
            } else {
              this.isWalletConnecting$$.next(false);
            }

            if (data.status === 'connected') {
              this.updateExternalBalance();
              localStorage.setItem('walletState', 'connected');
            } else if (data.status === 'disconnected') {
              localStorage.removeItem('walletState');
            }
            this.walletStatus$$.next(data.status);
          },
        });

        watchChainId(this.config, {
          onChange: (data) => {
            console.log('watchChainId');

            console.log(data);
          },
        });

        watchClient(this.config, {
          onChange: (data) => {
            console.log('watchClient');

            console.log(data);
          },
        });

        watchConnections(this.config, {
          onChange: (data) => {
            console.log('watchConnections');

            console.log(data);
          },
        });

        watchConnectors(this.config, {
          onChange: (data) => {
            console.log('watchConnectors');

            console.log(data);
          },
        });

        watchPublicClient(this.config, {
          onChange: (data) => {
            console.log('watchPublicClient');

            console.log(data);
          },
        });
      },
    });
  }

  connectWallet() {
    this.modal.open().then(() => (this.modalInitiated = true));
    if (!this.modalInitiated) {
      this.modal.subscribeEvents((state: any) => {
        if (USER_EVENTS.includes(state.data.event))
          this.notificationsService.openNotification(state.data.event);
      });
    }
  }

  disconnectWallet() {
    disconnect(this.config);
  }

  private async updateExternalBalance() {
    const { address } = getAccount(this.config);
    if (!address) return;
    const balance = await this.getBalance(address);
    this.store.dispatch(setBalance({ balance }));
    this.store.dispatch(setAddress({ address }));
  }

  getWalletState(): Observable<WalletStatus> {
    return this.walletStatus$;
  }

  isWalletConnecting(): Observable<boolean> {
    return this.isWalletConnecting$;
  }

  getWalletAddress() {
    const { address } = getAccount(this.config);
    return address;
  }

  async sendERC20Transaction(amount: string) {
    const address = this.getWalletAddress();

    if (!address) return;
    const balance = await this.getBalance(address);
    if (this.insufficientFunds(balance, amount)) {
      this.notificationsService.openNotification('INSUFFICIENT_DEPOSIT_FUNDS');
      return;
    }

    try {
      const hash = await writeContract(this.config, {
        abi: ERC20ABI,
        address: this.smartContractAddress as Address,
        functionName: 'transfer',
        args: [this.depositWalletAddress, parseEther(amount)],
      });
      this.notificationsService.openNotification('DEPOSIT_INITIATED');
      const receipt = await waitForTransaction(this.config, {
        hash: hash,
      });
      this.registerDepositing(hash, address, amount);

      if (receipt.status === 'success') {
        this.updateExternalBalance();
        return true;
      } else {
        this.notificationsService.openNotification('DEPOSIT_FAILED');
        return false;
      }
    } catch (e: any) {
      if (e.message.toString().startsWith('User rejected the request')) {
        this.notificationsService.openNotification('DEPOSIT_DENIED');
      }
      return false;
    }
  }

  createWithdrawal(amount: number) {
    const { address } = getAccount(this.config);
    if (!address) return of();
    return this.financeService.createWithdrawal(
      address,
      amount,
      this.currencyId,
    );
  }

  private async getBalance(address: Address) {
    const data = (await readContract(this.config, {
      abi: ERC20ABI,
      address: this.smartContractAddress as Address,
      functionName: 'balanceOf',
      args: [address],
    })) as bigint;
    return formatUnits(data, 18);
  }

  private registerDepositing(
    transactionHash: string,
    from: string,
    amount: string,
  ) {
    this.financeService
      .registerDepositing(transactionHash, from, amount, this.currencyId)
      .subscribe({
        next: () =>
          this.notificationsService.openNotification('DEPOSIT_SUCCESS'),
      });
  }

  private insufficientFunds(str1: string, str2: string): boolean {
    const num1 = parseFloat(str1);
    const num2 = parseFloat(str2);
    return num1 < num2;
  }

  private getWeb3Settings() {
    return this.settingsService.getWeb3Settings();
  }
}
