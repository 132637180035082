import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private notificationQueue: string[] = [];
  private currentNotification = new BehaviorSubject<string | null>(null);

  constructor(private translateService: TranslateService) {}

  openNotification(message: string) {
    this.notificationQueue.push(this.translateService.instant(message));
    this.showNextNotification();
  }

  getNotificationObservable(): Observable<string | null> {
    return this.currentNotification.asObservable();
  }

  private showNextNotification() {
    if (
      this.currentNotification.value === null &&
      this.notificationQueue.length > 0
    ) {
      const nextMessage = this.notificationQueue.shift();

      if (nextMessage) this.currentNotification.next(nextMessage);
    }
  }

  closeCurrentNotification() {
    this.currentNotification.next(null);
    this.showNextNotification();
  }
}
